var data = {
    'accuracy': {'tr': 'Doğruluk Oranı', 'en': 'Accuracy Ratio'},
    'active': {'tr': 'Aktif', 'en': 'Active'},
    'active_date_filter': {'tr': 'Aktif Tarih', 'en': 'Active Date'},
    'active_products': {'tr': 'Aktif Ürünlerim', 'en': 'Active Products'},
    'add_all_filtered_options': {'tr': 'Tüm filtreli seçenekleri ekle', 'en': 'Add all filtered options'},
    'add_to_shopping_cart': {'tr': 'Alışveriş Sepetine Ekle', 'en': 'Add To Shopping Cart'},
    'agreement': {'tr': 'Sözleşme', 'en': 'Agreement'},
    'agreement_preview': {'tr': 'Sözleşme Önizleme', 'en': 'Agreement Preview'},
    'agreement_type': {'tr': 'Sözleşme Tipi', 'en': 'Agreement Type'},
    'all': {'tr': 'hepsi', 'en': 'all'},
    'all_recorded_values': {'tr': 'tüm tespit edilen kayıtlar', 'en': 'all recorded values'},
    'already_added': {'tr': 'Zaten Eklenmiş', 'en': 'Already Added'},
    'analyze': {'tr': 'Analiz', 'en': 'Analyze'},
    'and': {'tr': 've', 'en': 'and'},
    'application_name': {'tr': 'Uygulama Adı', 'en': 'Application Name'},
    'application_settings': {'tr': 'Uygulama Ayarları', 'en': 'Application Settings'},
    'approve_shopcart': {'tr': 'Sepeti Onayla', 'en': 'Approve Shop Cart'},
    'are_shop_pages_active': {'tr': 'mağaza sayfaları aktif mi', 'en': 'are shop pages active'},
    'auto_loading': {'tr': 'Otomatik Yüklenme', 'en': 'Auto Loading'},
    'available_soon': {'tr': 'Yakında', 'en': 'Available Soon'},
    'begin_date': {'tr': 'Başlangıç Tarihi', 'en': 'Begin Date'},
    'bill_cancel_date_filter': {'tr': 'Fatura İptal', 'en': 'Bill Cancel'},
    'bill_cancelled': {'tr': 'Fatura iptal', 'en': 'Bill cancelled'},
    'bill_date_filter': {'tr': 'Fatura', 'en': 'Bill'},
    'bottom': {'tr': 'en aşağı', 'en': 'bottom'},
    'bundle': {'tr': 'Paket', 'en': 'Bundle'},
    'bundle_group': {'tr': 'Paket Grubu', 'en': 'Bundle Group'},
    'bundle_is_not_selected': {'tr': 'Mağaza paket seçimi yapılmadı', 'en': 'Shop bundle is not selected'},
    'bundle_parameter_filter': {'tr': 'Paket Filtresi', 'en': 'Bundle Filter'},
    'bundle_status_filter': {'tr': 'Paket', 'en': 'Bundle'},
    'calculate_case_count': {'tr': 'Satır Adedini Hesapla', 'en': 'Calculate Case Count'},
    'cancel': {'tr': 'İptal', 'en': 'Cancel'},
    'cancel_completed': {'tr': 'İptal işlemi tamamlandı.', 'en': 'Cancel operation completed'},
    'cancel_date_filter': {'tr': 'İptal', 'en': 'Cancel'},
    'captcha_code_incorrect': {'tr': 'Kod hatalı girdiniz.', 'en': 'Code is incorrect'},
    'caption': {'tr': 'Başlık', 'en': 'Caption'},
    'case_limit_count': {'tr': 'Satır Limiti', 'en': 'Row Limit'},
    'case_limit_max_value': {'tr': 'Maksimum Satır Limiti Uyarısı', 'en': 'Case Limit Maximum Warning'},
    'case_selection': {'tr': 'Vaka seçimi', 'en': 'Case selection'},
    'categorical': {'tr': 'Kategorik', 'en': 'Categorical'},
    'categorical_data_selection': {'tr': 'Kategorik Veri Seçimi', 'en': 'Categorical Data Selection'},
    'change': {'tr': 'değiştir', 'en': 'change'},
    'character_count_is_higher_than_expected': {'tr': 'Karakter adedi beklenenden fazla', 'en': 'Character count is higher than expected'},
    'clear': {'tr': 'Temizle', 'en': 'Clear'},
    'click_for_agreement_preview': {'tr': 'Sözleşme Önizleme İçin Tıklayınız', 'en': 'Click For Agreement Preview'},
    'click_for_details': {'tr': 'detaylar için tıklayınız', 'en': 'click for details'},
    'click_for_details_with_point': {'tr': 'detaylar için tıklayınız...', 'en': 'click for details...'},
    'client_portal': {'tr': 'Müşteri Giriş', 'en': 'Client Portal'},
    'cluster_centers': {'tr': 'küme merkezleri', 'en': 'cluster centers'},
    'column_general_info': {'tr': 'Kolon Genel Bilgiler', 'en': 'Column General Info'},
    'column_list': {'tr': 'Kolonlar', 'en': 'Column List'},
    'column_options': {'tr': 'Kolon Seçenekleri', 'en': 'Column Options'},
    'column_root_relation_type': {'tr': 'kolon kök ilişkisi tipi', 'en': 'column root relation type'},
    'column_rule_and_functions': {'tr': 'Sütun Kuralı ve Fonksiyonları', 'en': 'Column Rule And Functions'},
    'comment_add': {'tr': 'Yorum Ekle', 'en': 'Comment Add'},
    'comments': {'tr': 'Yorumlar', 'en': 'Comments'},
    'component_name': {'tr': 'Komponent Başlığı', 'en': 'Component Name'},
    'confusion_matrix': {'tr': 'konfüzyon matriks', 'en': 'confusion matrix'},
    'contact_us': {'tr': 'İletişim', 'en': 'Contact Us'},
    'continuing': {'tr': 'devam ediyor', 'en': 'continuing'},
    'copy': {'tr': 'kopyala', 'en': 'copy'},
    'create_column': {'tr': 'Kolon oluşturun', 'en': 'Create column'},
    'create_date': {'tr': 'Oluşturma Tarihi', 'en': 'Create Date'},
    'create_new_product_bundle': {'tr': 'Yeni ürün paketi oluştur', 'en': 'Create New Product Bundle'},
    'credit_card_cvv': {'tr': 'CVV', 'en': 'CVV'},
    'credit_card_expiration_date': {'tr': 'Son Kullanma Tarihi', 'en': 'Expiration Date'},
    'credit_card_holder': {'tr': 'Kart Sahibi', 'en': 'Card Holder'},
    'credit_card_mm': {'tr': 'AY', 'en': 'MM'},
    'credit_card_month': {'tr': 'Ay', 'en': 'Month'},
    'credit_card_name': {'tr': 'Kart Üzerindeki Ad', 'en': 'Card Name'},
    'credit_card_name_surname': {'tr': 'Ad Soyad', 'en': 'Name Surname'},
    'credit_card_number': {'tr': 'Kart Numarası', 'en': 'Card Number'},
    'credit_card_year': {'tr': 'Yıl', 'en': 'Year'},
    'credit_card_yy': {'tr': 'YIL', 'en': 'YY'},
    'currency_amount': {'tr': 'Para Miktarı', 'en': 'Currency Amount'},
    'do_you_confirm_the_process': {'tr': 'İşlemi Onaylıyor musunuz?', 'en': 'Do You Confirm The Process?'},
    'subject_type': {'tr': 'Konu Tipi', 'en': 'Subject Type'},
    'mail_approve_code': {'tr': 'Mail Onay Kodu', 'en': 'Mail Approve Code'},
    'please_write_mail_approve_code': {'tr': 'Lütfen Mail Onay Kodunu Giriniz', 'en': 'Please Write Mail Approve Code'},
    'currency_type': {'tr': 'Para Birim Tipi', 'en': 'Currency Type'},
    'customer_address': {'tr': 'Müşteri Adresi', 'en': 'Customer Address'},
    'explanation': {'tr': 'Açıklama', 'en': 'Explanation'},
    'send': {'tr': 'Gönder', 'en': 'Send'},
    'customer_experience': {'tr': 'Müşteri Deneyimi', 'en': 'Customer Experience'},
    'customer_info': {'tr': 'Müşteri Bilgileri', 'en': 'Customer Info'},
    'customer_mail': {'tr': 'Müşteri Mail', 'en': 'Customer Mail'},
    'customer_name': {'tr': 'Müşteri Adı', 'en': 'Customer Name'},
    'customer_phone': {'tr': 'Müşteri Telefon', 'en': 'Customer Phone'},
    'data': {'tr': 'Data', 'en': 'Data'},
    'data_count': {'tr': 'Veri Adedi', 'en': 'Data Count'},
    'data_creation_type_rule': {'tr': 'Zaten Eklenmiş', 'en': 'Already Added'},
    'data_separately_divided_by_square_brackets': {'tr': 'veriler ayrı ayrı - köşeli parantez ile bölünerek', 'en': 'data separately - divided by square brackets'},
    'data_type': {'tr': 'Veri Tipi', 'en': 'Data Type'},
    'data_unit_sql': {'tr': 'Veri Birimi (SQL)', 'en': 'Data Unit (SQL)'},
    'dataset': {'tr': 'Dataset', 'en': 'Dataset'},
    'dataset_filter': {'tr': 'Dataset Filtreleme', 'en': 'Dataset Filter'},
    'dataset_stats_query': {'tr': 'dataset istatistik sorgula', 'en': 'dataset statistics query'},
    'dataset_update_time': {'tr': 'Dataset güncelleme tarihi', 'en': 'Dataset update time'},
    'date_of_birth': {'tr': 'Doğum Tarihi', 'en': 'Date Of Birth'},
    'delete': {'tr': 'Sil', 'en': 'Delete'},
    'delete_all_and_recreate': {'tr': 'Verileri Yeniden Hazırla', 'en': 'Delete All And Recreate'},
    'detail_caption': {'tr': 'Detay Başlığı', 'en': 'Detail Caption'},
    'detail_code': {'tr': 'Detay Kod', 'en': 'Detail Code'},
    'detail_explanation': {'tr': 'Detay Açıklaması', 'en': 'Detail Explanation'},
    'detail_iframe': {'tr': 'Detay Iframe', 'en': 'Detail Iframe'},
    'detail_image': {'tr': 'Detay Resim', 'en': 'Detail Image'},
    'detail_link': {'tr': 'Detay Link', 'en': 'Detail Link'},
    'detail_mail': {'tr': 'Detay Mail', 'en': 'Detail Mail'},
    'details': {'tr': 'Detaylar', 'en': 'Details'},
    'developing': {'tr': 'Geliştirme devam ediyor', 'en': 'Development is continueing'},
    'down': {'tr': 'aşağı', 'en': 'down'},
    'edit': {'tr': 'Düzenle', 'en': 'Edit'},
    'edit_main_rule_algorithm': {'tr': 'Ana Kuralı Düzenle', 'en': 'Edit Main Rule'},
    'end_date': {'tr': 'Bitiş Tarihi', 'en': 'End Date'},
    'enter_password': {'tr': 'Şifre Girin', 'en': 'Enter Password'},
    'enter_username': {'tr': 'Kullanıcı adınızı girin', 'en': 'Enter Username'},
    'external_application_list': {'tr': 'Eksternal Uygulama Listesi', 'en': 'External Application List'},
    'external_application_record': {'tr': 'Eksternal Uygulama Kayıt', 'en': 'External Application Record'},
    'facebook_link': {'tr': 'facebook link', 'en': 'facebook link'},
    'false_negative_explanation': {'tr': 'Yanlış Negatif: Aslında pozitif olana model negatif demiştir. Yanlış tahmin', 'en': 'False Negative: The model called it negative when it was actually positive. Wrong prediction'},
    'false_positive_explanation': {'tr': 'Yanlış Pozitif: Aslında negatif olana model pozitif demiştir. Yanlış tahmin', 'en': 'False Positive: The model called it positive when it was actually negative. Wrong prediction'},
    'file_extension_type_is_not_eligible': {'tr': 'Dosya tipi uygun değil', 'en': 'File extension type is not eligible'},
    'file_size_is_not_eligible': {'tr': 'Dosya boyutu uygun değil', 'en': 'File size is not eligible'},
    'filter_field': {'tr': 'Filtre Alanı', 'en': 'Filter Field'},
    'filter_operation_type': {'tr': 'Filtre', 'en': 'Filter'},
    'first_create_column': {'tr': 'Önce kolon oluşturun', 'en': 'First create column'},
    'first_value_date_is_older': {'tr': 'ilk tespit edilen kayıt - tarihi en geçmiş olan', 'en': 'first value - date is older'},
    'firstname': {'tr': 'Ad', 'en': 'Firstname'},
    'follow_us': {'tr': 'Bizi Takip Edin', 'en': 'Follow Us'},
    'footer_background_color': {'tr': 'footer arkaplan rengi', 'en': 'footer background color'},
    'footer_settings': {'tr': 'Footer Ayarları', 'en': 'Footer Settings'},
    'footer_text_color': {'tr': 'footer yazı rengi', 'en': 'footer text color'},
    'frontend_list': {'tr': 'Frontend Listesi', 'en': 'Frontend List'},
    'function': {'tr': 'Fonksiyon', 'en': 'Function'},
    'gender': {'tr': 'Cinsiyet', 'en': 'Gender'},
    'general_features': {'tr': 'Genel özellikler', 'en': 'General Features'},
    'general_settings': {'tr': 'Genel Ayarları', 'en': 'General Settings'},
    'go_to_dashboard': {'tr': 'Ana Sayfaya Dön', 'en': 'Go To Dashboard'},
    'highlights': {'tr': 'Öne çıkan özellikler', 'en': 'Highlights'},
    'i_approve_agreement': {'tr': 'Sözleşmeleri okudum, anladım, onaylıyorum', 'en': 'I have read, understood and approved the agreements'},
    'i_want_to_get_information_about_bundle_by_online_meeting': {'tr': 'Paket hakkında online toplantı ile bilgi almak istiyorum', 'en': 'I want to get information about this bundle by online meeting'},
    'if_it_has_been_recorded_before_rewrite_it_when_editing_is_necessary': {'tr': 'Daha önce kaydedildiyse, sadece düzenleme gerektiğinde yeniden yazınız.', 'en': 'If it has been recorded before, rewrite it when only editing is necessary'},
    'image_upload': {'tr': 'Resim Yükleme', 'en': 'Image Upload'},
    'info': {'tr': 'Açıklama', 'en': 'Info'},
    'init': {'tr': 'Init', 'en': 'Init'},
    'inputs': {'tr': 'Girdiler', 'en': 'Inputs'},
    'instagram_link': {'tr': 'instagram link', 'en': 'instagram link'},
    'kernel_type': {'tr': 'Kernel Tipi', 'en': 'Kernel Type'},
    'key_create': {'tr': 'Anahtar üret', 'en': 'Key create'},
    'label_usage': {'tr': 'Başlık Kullan', 'en': 'Label Usage'},
    'last_update': {'tr': 'Son Güncelleme', 'en': 'Last Update'},
    'last_value_date_is_older': {'tr': 'son tespit edilen kayıt - tarihi en yeni olan', 'en': 'last value - date is newer'},
    'lastname': {'tr': 'Soyad', 'en': 'Lastname'},
    'legal_settings': {'tr': 'Legal Ayarları', 'en': 'Legal Settings'},
    'linkedin_link': {'tr': 'linkedin_link', 'en': 'linkedin_link'},
    'loading': {'tr': 'İşlem Devam Ediyor', 'en': 'Loading'},
    'location': {'tr': 'Lokasyon', 'en': 'Location'},
    'login': {'tr': 'Giriş', 'en': 'Login'},
    'login_background_color': {'tr': 'Login Arka Plan Rengi', 'en': 'Login Background Color'},
    'login_background_image': {'tr': 'login arka plan resmi', 'en': 'login background image'},
    'login_background_image_by_link': {'tr': 'login arka plan resmi linki', 'en': 'login background image link'},
    'login_buttons_background_color': {'tr': 'Login Butonlar Arka Plan Rengi', 'en': 'Login Buttons Background Color'},
    'login_card_in_background_color': {'tr': 'Login Iç Dış Arka Plan Rengi', 'en': 'Login Card In Background Color'},
    'login_card_in_shadow_color': {'tr': 'Login Iç Dış Gölge Rengi', 'en': 'Login Card In Shadow Color'},
    'login_card_out_background_color': {'tr': 'Login Kard Dış Arka Plan Rengi', 'en': 'Login Card Out Background Color'},
    'login_card_out_shadow_color': {'tr': 'Login Kard Dış Gölge Rengi', 'en': 'Login Card Out Shadow Color'},
    'login_function': {'tr': 'Login Fonksiyonu', 'en': 'Login Function'},
    'login_function_external': {'tr': 'Eksternal Login Fonksiyonu', 'en': 'Login Function External'},
    'login_inputs_border_color': {'tr': 'Login Girdi Alanları Sınır Rengi', 'en': 'Login Inputs Border Color'},
    'login_is_continueing_please_wait': {'tr': 'login devam ediyor. Lütfen bekleyiniz.', 'en': 'login is continueing please wait'},
    'login_loading_background_color': {'tr': 'Login Loading Arka Plan Rengi', 'en': 'Login Loading Background Color'},
    'login_screen': {'tr': 'Giriş Ekranı', 'en': 'Login Screen'},
    'login_settings': {'tr': 'Login Ayarları', 'en': 'Login Settings'},
    'login_slogan_text': {'tr': 'Login Slogan Text', 'en': 'Login Slogan Text'},
    'machine_learning_model': {'tr': 'Makine Öğrenmesi Modeli', 'en': 'Machine Learning Model'},
    'machine_learning_model_assign': {'tr': 'Makine Öğrenmesi Modeli Atama', 'en': 'Machine Learning Model Assign'},
    'machine_learning_query': {'tr': 'makine öğrenmesi sorgulama', 'en': 'machine learning query'},
    'mail': {'tr': 'Mail', 'en': 'Mail'},
    'mail_settings': {'tr': 'Mail Ayarları', 'en': 'Mail Settings'},
    'main_layer': {'tr': 'Ana Katman', 'en': 'Main Layer'},
    'main_rule': {'tr': 'Ana Kural', 'en': 'Main Rule'},
    'manuel': {'tr': 'Manuel', 'en': 'Manuel'},
    'manuel_column_design_type': {'tr': 'Manuel Column Tasarım Tipi', 'en': 'Manuel Column Design Type'},
    'max': {'tr': 'maksimum', 'en': 'max'},
    'maximum_key_character_count_32': {'tr': 'Maksimum anahtar karakter adedi 32', 'en': 'Maximum key character count is 32'},
    'mean': {'tr': 'ortalama', 'en': 'mean'},
    'median': {'tr': 'medyan', 'en': 'median'},
    'menu_background_color': {'tr': 'Menü Arka Plan Rengi', 'en': 'Menu Background Color'},
    'menu_border_bottom_color': {'tr': 'Menü Alt Sınır Rengi', 'en': 'Menu Border Bottom Color'},
    'menu_box_shadow_color': {'tr': 'Menü Kenar Gölgesi Rengi', 'en': 'Menu Box Shadow Color'},
    'menu_child_item_background_color': {'tr': 'Menü Alt Başlık Arka Plan Rengi', 'en': 'Menu Child Item Background Color'},
    'menu_child_item_border_bottom_color': {'tr': 'Menü Alt Başlık Alt Sınır Rengi', 'en': 'Menu Child Item Border Bottom Color'},
    'menu_child_item_text_color': {'tr': 'Menü Alt Başlık Yazı Rengi', 'en': 'Menu Child Item Text Color'},
    'menu_parent_item_background_color': {'tr': 'Menü Ana Başlık Arka Plan Rengi', 'en': 'Menu Parent Item Background Color'},
    'menu_parent_item_border_bottom_color': {'tr': 'Menü Ana Başlık Alt Sınır Rengi', 'en': 'Menu Parent Item Border Bottom Color'},
    'menu_parent_item_text_color': {'tr': 'Menü Ana Başlık Yazı Rengi', 'en': 'Menu Parent Item Text Color'},
    'menu_settings': {'tr': 'Menü Ayarları', 'en': 'Menu Settings'},
    'menu_top_caption': {'tr': 'Menü Üst Birim Başlık', 'en': 'Menu Top Caption'},
    'merge_all_records': {'tr': 'tüm kayıtları birleştir', 'en': 'merge all records'},
    'meta': {'tr': 'Meta', 'en': 'Meta'},
    'meta_value': {'tr': 'Meta Değeri', 'en': 'Meta Value'},
    'min': {'tr': 'minimum', 'en': 'min'},
    'model_features': {'tr': 'Model Özellikleri', 'en': 'Model Features'},
    'my_shopcart': {'tr': 'Sepetim', 'en': 'Shopping Cart'},
    'n_clusters': {'tr': 'N Kümeleri', 'en': 'N Clusters'},
    'navbar_application_name_color': {'tr': 'Navbar Uygulama Adı Rengi', 'en': 'Navbar Application Name Color'},
    'navbar_application_name_shadow_color': {'tr': 'Navbar Uygulama Adı Yazı Gölge Rengi', 'en': 'Navbar Application Name Shadow Color'},
    'navbar_background_color': {'tr': 'Navbar Arka Plan Rengi', 'en': 'Navbar Background Color'},
    'navbar_middle_logo': {'tr': 'Navbar Orta Logo', 'en': 'Navbar Middle Logo'},
    'navbar_settings': {'tr': 'Navbar Ayarları', 'en': 'Navbar Settings'},
    'navbar_text_color': {'tr': 'navbar yazı rengi', 'en': 'navbar text color'},
    'negative': {'tr': 'Negatif', 'en': 'Negative'},
    'new_feature': {'tr': 'Yeni Özellik', 'en': 'New Feature'},
    'new_layer': {'tr': 'Yeni Katman', 'en': 'New Layer'},
    'new_layer_column': {'tr': 'Yeni Katman Kolonu', 'en': 'New Layer Column'},
    'new_location': {'tr': 'Yeni Lokasyon', 'en': 'New Location'},
    'new_manuel_column': {'tr': 'Yeni Manuel Kolon', 'en': 'New Manuel Column'},
    'new_option': {'tr': 'Yeni Seçenek', 'en': 'New Option'},
    'new_page_component': {'tr': 'Yeni Sayfa Komponenti', 'en': 'New Page Component'},
    'new_page_content': {'tr': 'Yeni Sayfa İçeriği', 'en': 'New Page Content'},
    'new_project': {'tr': 'Yeni Proje', 'en': 'New Project'},
    'new_server': {'tr': 'Yeni Sunucu', 'en': 'New Server'},
    'new_support_request': {'tr': 'Yeni Destek Talebi', 'en': 'New Support Request'},
    'new_user_externalapplication': {'tr': 'Yeni Eksternal Uygulama', 'en': 'New External Application'},
    'new_user_shop': {'tr': 'Yeni Mağaza', 'en': 'New Shop'},
    'new_user_shop_bundle': {'tr': 'Yeni Paket', 'en': 'New Bundle'},
    'new_user_shop_bundle_agreement': {'tr': 'Pakete Sözleşme Ekle', 'en': 'Add Agreement To Bundle'},
    'new_user_shop_bundle_product': {'tr': 'Pakete Ürün Ekle', 'en': 'Add Product To Bundle'},
    'new_user_shop_bundle_product_agreement': {'tr': 'Paket Ürününe Sözleşme Ekle', 'en': 'Add Agreement To Bundle Product'},
    'new_websiteunit': {'tr': 'Yeni Website Birimi', 'en': 'New Website Unit'},
    'no': {'tr': 'hayır', 'en': 'no'},
    'none': {'tr': 'yok', 'en': 'none'},
    'numeric': {'tr': 'Numerik', 'en': 'Numeric'},
    'numeric_categorical': {'tr': 'Numerik & Kategorik', 'en': 'Numeric & Categorical'},
    'numeric_usage_type': {'tr': 'Numerik Kullanım Şekli', 'en': 'Numeric Usage Type'},
    'or': {'tr': 'veya', 'en': 'or'},
    'order': {'tr': 'Sipariş', 'en': 'Order'},
    'order_approve': {'tr': 'Sipariş Onayla', 'en': 'Order Approve'},
    'order_brief': {'tr': 'Sipariş Özeti', 'en': 'Order Summary'},
    'order_create': {'tr': 'Sipariş Oluştur', 'en': 'Order'},
    'order_date_filter': {'tr': 'Sipariş', 'en': 'Order'},
    'order_info': {'tr': 'Sipariş oluşturduktan sonra lütfen havale/eft ile ödemenizi gerçekleştirmeyi unutmayınız. Sonrasında ürün paketi ekibimiz tarafından kontrol edilerek aktive edilecektir.', 'en': 'After placing your order, please do not forget to make your payment by money order/EFT. Afterwards, the product package will be checked and activated by our team.'},
    'order_list': {'tr': 'Sipariş Listesi', 'en': 'Order List'},
    'outputs': {'tr': 'Çıktılar', 'en': 'Outputs'},
    'page_area_settings': {'tr': 'Sayfa Alanı Ayarları', 'en': 'Page Area Settings'},
    'page_area_text_color': {'tr': 'sayfa alanı yazı rengi', 'en': 'page area text color'},
    'passive': {'tr': 'Pasif', 'en': 'Passive'},
    'passive_date_filter': {'tr': 'Pasif', 'en': 'Passive'},
    'password': {'tr': 'Şifre', 'en': 'Password'},
    'password_change': {'tr': 'Şifre değiştir', 'en': 'Password change'},
    'password_create': {'tr': 'Şifre Üret', 'en': 'Password Create'},
    'password_minimum_eight_characters': {'tr': 'Şifre Minimum 8 karakter', 'en': 'Password Minimum 8 Characters'},
    'password_reset': {'tr': 'Şifre sıfırlama', 'en': 'Password reset'},
    'password_retype': {'tr': 'Şifre tekrar', 'en': 'Retype password'},
    'pay': {'tr': 'Öde', 'en': 'Pay'},
    'pay_with_credit_card': {'tr': 'Kredi Kartı İle Öde', 'en': 'Pay With Credit Card'},
    'pay_with_money_transfer': {'tr': 'Para Transferi (EFT) İle Öde', 'en': 'Pay With Money Transfer'},
    'payment_approve': {'tr': 'Ödeme Onayla', 'en': 'Payment Approve'},
    'payment_approved': {'tr': 'Ödeme onaylandı', 'en': 'Payment approved'},
    'payment_cancel': {'tr': 'Ödeme iptal', 'en': 'Payment cancel'},
    'payment_date': {'tr': 'Ödeme Tarihi', 'en': 'Payment Date'},
    'payment_date_filter': {'tr': 'Ödeme', 'en': 'Payment'},
    'payment_details': {'tr': 'Ödeme Detayları', 'en': 'Payment Details'},
    'payment_method': {'tr': 'Ödeme Metodu', 'en': 'Payment Method'},
    'payment_refund_date_filter': {'tr': 'Ödeme İade', 'en': 'Payment Refund'},
    'payment_refunded': {'tr': 'Ödeme iade', 'en': 'Payment refunded'},
    'payment_type': {'tr': 'Ödeme Tipi', 'en': 'Payment Type'},
    'payment_waiting': {'tr': 'Ödeme bekliyor', 'en': 'Payment waiting'},
    'phone_number': {'tr': 'Telefon No', 'en': 'Phone Number'},
    'phone_number_with_country_code': {'tr': 'Telefon No Ülke Kodu İle', 'en': 'Phone Number With Country Code'},
    'please_check_parenthesis': {'tr': 'Lütfen parantezleri kontrol ediniz.', 'en': 'Please check the parenthesis'},
    'please_enter_captcha_code': {'tr': 'lütfen kodu giriniz', 'en': 'please enter the code'},
    'please_fill': {'tr': 'Lütfen doldurunuz.', 'en': 'Please fill'},
    'please_fill_all_inputs': {'tr': 'Lütfen tüm girdileri doldurunuz.', 'en': 'Please fill all inputs.'},
    'please_input_id': {'tr': 'Lütfen analiz edilecek id değerini giriniz', 'en': 'Please write id value to analyze'},
    'please_input_sql_name_database_unit': {'tr': 'Lütfen analiz veritabanı birimi değerini giriniz', 'en': 'Please enter the analysis database unit value'},
    'please_read_and_approve_agreements': {'tr': 'Lütfen sözleşmeleri okuyup onaylayınız.', 'en': 'Please read and approve agreements'},
    'please_select_gender': {'tr': 'Lütfen cinsiyt seçimi yapınız', 'en': 'Please select gender.'},
    'please_write_firstname': {'tr': 'Lütfen adınızı yazınız', 'en': 'Please write firstname'},
    'please_write_lastname': {'tr': 'Lütfen soyadınızı yazınız', 'en': 'Please write lastname'},
    'please_write_mail': {'tr': 'Lütfen mailinizi yazınız', 'en': 'Please write your mail'},
    'please_write_true_mail': {'tr': 'Lütfen doğru email formatında giriniz', 'en': 'Please write as true mail format'},
    'please_write_your_comment': {'tr': 'Lütfen yorum yazınız', 'en': 'Please write your comment'},
    'positive': {'tr': 'Pozitif', 'en': 'Positive'},
    'precision': {'tr': 'Kesinlik', 'en': 'Precision'},
    'prediction_values': {'tr': 'Tahmin Değerleri', 'en': 'Prediction Values'},
    'preview': {'tr': 'Önizle', 'en': 'Preview'},
    'price': {'tr': 'fiyat', 'en': 'price'},
    'pricing': {'tr': 'Fiyatlandırma', 'en': 'Pricing'},
    'privacy_policy_approve': {'tr': 'Gizlilik politikasını kabul ediyorum', 'en': 'I accept privacy policy'},
    'privacy_policy_show': {'tr': 'Gizlilik politikası görüntüle', 'en': 'Show privacy policy'},
    'process': {'tr': 'İşlem', 'en': 'Process'},
    'process_completed': {'tr': 'İşlem Tamamlandı', 'en': 'Process Completed'},
    'bill_completed': {'tr': 'Fatura Tamamlandı', 'en': 'Bill Completed'},
    'process_continuing_please_wait': {'tr': 'İşleminiz gerçekleştiriliyor. Lütfen bekleyiniz', 'en': 'Process continueing. Please wait.'},
    'product': {'tr': 'Ürün', 'en': 'Product'},
    'product_count': {'tr': 'Ürün Adedi', 'en': 'Product Count'},
    'product_details': {'tr': 'Ürün Detayları', 'en': 'Product Details'},
    'product_general_settings': {'tr': 'Ürün genel ayarları', 'en': 'Product general settings'},
    'product_ingredients': {'tr': 'Ürün içeriği', 'en': 'Product Ingredients'},
    'product_list': {'tr': 'Ürün Listesi', 'en': 'Product List'},
    'product_payment_screen': {'tr': 'Ürün Ödeme Ekranı', 'en': 'Product Payment Screen'},
    'product_preparation': {'tr': 'Ürün hazırlama', 'en': 'Product preparation'},
    'product_representative': {'tr': 'Ürün Temsilcisi', 'en': 'Product representative'},
    'product_return': {'tr': 'Ürün iade', 'en': 'Product return'},
    'project_name': {'tr': 'Proje Adı', 'en': 'Project Name'},
    'projects': {'tr': 'Projeler', 'en': 'Projects'},
    'purchased': {'tr': 'Satın Alınan', 'en': 'Purchased'},
    'random_data_create': {'tr': 'Rastgele Veri Oluşturma', 'en': 'Random Data Create'},
    'random_state': {'tr': 'Random State', 'en': 'Random State'},
    'real_values': {'tr': 'Gerçek Değerler', 'en': 'Real Values'},
    'recall': {'tr': 'Duyarlılık', 'en': 'Recall'},
    'register': {'tr': 'Kayıt', 'en': 'Register'},
    'register_approve_completed': {'tr': 'Kayıt onaylama tamamlandı', 'en': 'Register approve completed'},
    'register_approve_not_accepted': {'tr': 'Kayıt onaylanmadı', 'en': 'Register approve not accepted'},
    'register_approve_waiting': {'tr': 'Kayıt onay bekleniyor', 'en': 'Register approve waiting'},
    'register_done_please_mail_approve_check': {'tr': 'Kayıt işlemi tamamlandı. Lütfen onay linkini mailinizden kontrol ediniz.', 'en': 'Registration is complete. Please check the confirmation link in your email.'},
    'register_settings': {'tr': 'Kayıt Ayarları', 'en': 'Register Settings'},
    'reset': {'tr': 'Reset', 'en': 'Reset'},
    'results': {'tr': 'Sonuçlar', 'en': 'Results'},
    'root_column': {'tr': 'kök kolon', 'en': 'root column'},
    'same_values_approach': {'tr': 'aynı değerlere yaklaşım', 'en': 'same values approach'},
    'same_values_not_use': {'tr': 'aynı değerleri kullanma', 'en': 'same values not use'},
    'same_values_use': {'tr': 'aynı değerleri kullan', 'en': 'same values use'},
    'save': {'tr': 'Kaydet', 'en': 'Save'},
    'save_changes': {'tr': 'Değişiklikleri Kaydet', 'en': 'Save Changes'},
    'save_new_payment_data': {'tr': 'Yeni Ödeme Bilgilerini Kaydet', 'en': 'Save New Payment Data'},
    'search': {'tr': 'ara', 'en': 'search'},
    'search_by_query': {'tr': 'Sorgu İle Ara', 'en': 'Search By Query'},
    'search_by_user': {'tr': 'Kullanıcı İle Ara', 'en': 'Search By User'},
    'select_bundle_agreement': {'tr': 'Paket Sözleşme Seçimi', 'en': 'Select Bundle Agreement'},
    'select_bundle_product_agreement': {'tr': 'Paket Ürünü Sözleşme Seçimi', 'en': 'Select Bundle Product Agreement'},
    'select_image': {'tr': 'Resimi Seç', 'en': 'Select Image'},
    'send_reset_mail': {'tr': 'Sıfırlama maili gönder', 'en': 'Send reset mail'},
    'sending_the_product_to_the_customer': {'tr': 'Ürünü gönder', 'en': 'Product send'},
    'shop': {'tr': 'Mağaza', 'en': 'Shop'},
    'shop_bundle_agreement_list': {'tr': 'Mağaza Paket Sözleşme Listesi', 'en': 'Shop Bundle Agreement List'},
    'shop_bundle_list': {'tr': 'Mağaza Paket Listesi', 'en': 'Shop Bundle List'},
    'shop_bundle_product_agreement_list': {'tr': 'Mağaza Paket Ürünü Sözleşme Listesi', 'en': 'Shop Bundle Product Agreement List'},
    'shop_bundle_product_list': {'tr': 'Mağaza Paket Ürün Listesi', 'en': 'Shop Bundle Product List'},
    'shop_bundle_product_record': {'tr': 'Mağaza Paket Ürün Kayıt', 'en': 'Shop Bundle Product Record'},
    'shop_bundle_record': {'tr': 'Mağaza Paket Kayıt', 'en': 'Shop Bundle Record'},
    'shop_bundle_update_by_excel': {'tr': 'Mağaza Paketlerini Excelden Güncelle', 'en': 'Shop Bundle Update By Excel'},
    'shop_info': {'tr': 'Mağaza Bilgileri', 'en': 'Shop Info'},
    'shop_is_not_selected': {'tr': 'Mağaza seçimi yapılmadı', 'en': 'Shop is not selected'},
    'shop_name': {'tr': 'Mağaza Adı', 'en': 'Shop Name'},
    'shopcart_add': {'tr': 'Sepete Ekleme', 'en': 'Shopcart Add'},
    'shoplist': {'tr': 'Mağaza Listesi', 'en': 'Shop List'},
    'show_dates': {'tr': 'Tarihleri Göster', 'en': 'Show Dates'},
    'social_media_settings': {'tr': 'Sosyal Medya Ayarları', 'en': 'Social Media Settings'},
    'statistics_result_view': {'tr': 'istatistik sonucu göster', 'en': 'statistics result view'},
    'status': {'tr': 'Durum', 'en': 'Status'},
    'sum': {'tr': 'toplam', 'en': 'sum'},
    'support_details': {'tr': 'Destek Detayları', 'en': 'Support Details'},
    'support_operation_name': {'tr': 'Destek Adı', 'en': 'Support Name'},
    'support_operation_note': {'tr': 'Destek Notu', 'en': 'Support Note'},
    'support_operation_note_additional': {'tr': 'Destek Notu Ek Olarak', 'en': 'Support Additional Note'},
    'support_requests': {'tr': 'Destek Talepleri', 'en': 'Support Requests'},
    'support_screen': {'tr': 'Destek Ekranı', 'en': 'Support Screen'},
    'support_settings': {'tr': 'Destek Ayarları', 'en': 'Support Settings'},
    'support_terminate': {'tr': 'Desteği Sonlandır', 'en': 'Support Terminate'},
    'system_update_by_excel_file': {'tr': 'Sistemi Excel Dosyası İle Güncelle', 'en': 'System Update By Excel File'},
    'termination': {'tr': 'Sonlandırma', 'en': 'Termination'},
    'termination_date_filter': {'tr': 'Sonlandırma', 'en': 'Termination'},
    'terms_of_service_approve': {'tr': 'Hizmet şartlarını kabul ediyorum', 'en': 'I accept terms of service'},
    'terms_of_service_show': {'tr': 'Hizmet şartları görüntüle', 'en': 'Show terms of service'},
    'test_size': {'tr': 'Test Boyutu', 'en': 'Test Size'},
    'the_page_you_are_looking_for_was_not_found': {'tr': 'Aradığınız sayfa bulunamadı.', 'en': 'The page you are looking for was not found.'},
    'top': {'tr': 'en yukarı', 'en': 'top'},
    'total': {'tr': 'Toplam', 'en': 'Total'},
    'total_case_count': {'tr': 'Toplam Satır Adedi', 'en': 'Total Case Count'},
    'true_negative_explanation': {'tr': 'Doğru Negatif: Negatif olana model de negatif demiştir. Doğru tahmin', 'en': 'True Negative: The model also said negative. Correct prediction'},
    'true_positive_explanation': {'tr': 'Doğru Pozitif: Pozitif olana model de pozitif demiştir. Doğru tahmin.', 'en': 'True Positive: If it is positive, the model also said it was positive. Correct prediction.'},
    'twitter_link': {'tr': 'twitter link', 'en': 'twitter link'},
    'type': {'tr': 'Tipi', 'en': 'Type'},
    'up': {'tr': 'yukarı', 'en': 'up'},
    'update_payment_data': {'tr': 'Ödeme Bilgilerini Güncelle', 'en': 'Update Payment Data'},
    'updated': {'tr': 'Güncellendi', 'en': 'Updated'},
    'updated_at': {'tr': 'Güncelleme Tarihi', 'en': 'Update Date'},
    'user_search': {'tr': 'Kullanıcı Ara', 'en': 'User Search'},
    'user_shop_bundle_agreement_record': {'tr': 'Mağaza Paket Sözleşme Kayıt', 'en': 'Shop Bundle Agreement Record'},
    'user_shop_bundle_list': {'tr': 'Market Paket Listesi', 'en': 'Shop Bundle List'},
    'user_shop_bundle_product_agreement_record': {'tr': 'Mağaza Paket Ürün Sözleşme Kayıt', 'en': 'Shop Bundle Product Agreement Record'},
    'user_shop_order_list': {'tr': 'Market Sipariş Listesi', 'en': 'Shop Order List'},
    'user_shop_product_list': {'tr': 'Market Paket Ürün Listesi', 'en': 'Shop Bundle Product List'},
    'user_shopcart_bundle_filter': {'tr': 'Paket filtresi', 'en': 'Bundle filter'},
    'user_shopcart_bundle_product_filter': {'tr': 'Ürün filtresi', 'en': 'Product filter'},
    'user_shopcart_bundle_product_payment_filter': {'tr': 'Ödeme filtresi', 'en': 'Payment filter'},
    'username': {'tr': 'Kullanıcı Adı', 'en': 'Username'},
    'username_format': {'tr': 'ingilizce harfler, sayılar ve nokta işareti', 'en': 'english letters, numbers and dot sign'},
    'username_or_password_error': {'tr': 'Kullanıcı adı ya da şifre hatalı', 'en': 'Username or password error'},
    'value_label_usage': {'tr': 'Değer & Başlık Kullanımı', 'en': 'Value & Label Usage'},
    'value_multiplex_approach': {'tr': 'Veri Çoklamalarına Yaklaşım', 'en': 'Value Multiplexing Approach'},
    'value_usage': {'tr': 'Değer Kullan', 'en': 'Value Usage'},
    'variables': {'tr': 'Değişkenler', 'en': 'Variables'},
    'waccount_login': {'tr': 'Waccount Login', 'en': 'Waccount Login'},
    'waccount_settings': {'tr': 'Waccount Ayarları', 'en': 'Waccount Settings'},
    'wanalyzer_api_password': {'tr': 'wanalyzer api şifre', 'en': 'wanalyzer api password'},
    'wanalyzer_api_url': {'tr': 'wanalyzer api url', 'en': 'wanalyzer api url'},
    'wanalyzer_api_username': {'tr': 'wanalyzer api kullanıcı adı', 'en': 'wanalyzer api username'},
    'wanalyzer_cell_calculate': {'tr': 'Veri Hesaplamaları', 'en': 'Data Calculate'},
    'wanalyzer_create_query': {'tr': 'Veri Sorgusu Oluşturma', 'en': 'Create Data Query'},
    'wanalyzer_create_query_settings': {'tr': 'Wanalyzer sorgu oluşturma ayarları', 'en': 'Wanalyzer create query settings'},
    'wanalyzer_dataset_filter': {'tr': 'Dataset Filtreleme', 'en': 'Dataset Filter'},
    'wanalyzer_dataset_stats_query': {'tr': 'Dataset Statistics Query', 'en': 'Dataset İstatistik Sorgulama'},
    'wanalyzer_frontend_url': {'tr': 'wanalyzer frontend url', 'en': 'wanalyzer frontend url'},
    'wanalyzer_machinelearning_query': {'tr': 'Makine Öğrenmesi Tahminleme', 'en': 'Machine Learning Query'},
    'wanalyzer_product_settings': {'tr': 'Wanalyzer ürün ayarları', 'en': 'Wanalyzer product settings'},
    'wanalyzer_settings': {'tr': 'Wanalyzer Ayarları', 'en': 'Wanalyzer Settings'},
    'wanalyzer_stats_view': {'tr': 'İstatistik Görüntüleme', 'en': 'Statistics View'},
    'wdm_param_column_rule': {'tr': 'Kolon Kuralı', 'en': 'Column Rule'},
    'website_edit': {'tr': 'Website Düzenle', 'en': 'Website Edit'},
    'who_can_use': {'tr': 'Kim Kullanabilir ?', 'en': 'Who Can Use ?'},
    'wmanager_uri': {'tr': 'Wmanager Uri', 'en': 'Wmanager Uri'},
    'worganization_settings': {'tr': 'Worganization Ayarları', 'en': 'Worganization Settings'},
    'write_captcha_code': {'tr': 'Kodu giriniz.', 'en': 'Write the code'},
    'write_for_filtering': {'tr': 'Filtrelemek için yazınız.', 'en': 'Write For Filtering'},
    'yes': {'tr': 'evet', 'en': 'yes'},
    'you_are_being_directed_to_the_login_page': {'tr': 'login sayfasına yönlendiriliyorsunuz', 'en': 'You are being directed to the login page'},
    'html_editor': {'tr': 'Html Editör', 'en': 'Html Editor'},
    'text_editor': {'tr': 'Text Editör', 'en': 'Text Editor'},
    'background': {'tr': 'arkaplan', 'en': 'background'},
    'color': {'tr': 'renk', 'en': 'color'},
    'white': {'tr': 'beyaz', 'en': 'white'},
    'red': {'tr': 'kırmızı', 'en': 'red'},
    'blue': {'tr': 'mavi', 'en': 'blue'},
    'green': {'tr': 'yeşil', 'en': 'green'},
    'black': {'tr': 'siyah', 'en': 'black'},
    'size': {'tr': 'boyut', 'en': 'size'},
    'very_small': {'tr': 'çok küçük', 'en': 'very small'},
    'small': {'tr': 'küçük', 'en': 'small'},
    'normal': {'tr': 'normal', 'en': 'normal'},
    'medium_large': {'tr': 'orta büyük', 'en': 'medium large'},
    'large': {'tr': 'büyük', 'en': 'large'},
    'very_large': {'tr': 'çok büyük', 'en': 'very large'},
    'maximum_size': {'tr': 'maksimum büyüklükte', 'en': 'maximum size'},
    'title_1': {'tr': 'başlık 1', 'en': 'title 1'},
    'title_2': {'tr': 'başlık 2', 'en': 'title 2'},
    'title_3': {'tr': 'başlık 3', 'en': 'title 3'},
    'title_4': {'tr': 'başlık 4', 'en': 'title 4'},
    'title_5': {'tr': 'başlık 5', 'en': 'title 5'},
    'subtitle': {'tr': 'altbaşlık', 'en': 'subtitle'},
    'user_settings': {'tr': 'Kullanıcı Ayarları', 'en': 'User Settings'},
    'contact_settings': {'tr': 'İletişim Ayarları', 'en': 'Contact Settings'},
    'personal_settings': {'tr': 'Kişisel Ayarlar', 'en': 'Personal Settings'},
    'empty_input_message': {'tr': 'Gerekli tüm alanların doldurulması gerekmektedir.', 'en': 'All required fields must be filled in.'},
    'update_success_message': {'tr': 'Güncelleme işlemi tamamlandı.', 'en': 'Update process completed.'},
    'update_image': {'tr': 'Resimi Güncelle', 'en': 'Update Image'},
    'delete_image': {'tr': 'Resimi Sil', 'en': 'Delete Image'},
    'delete_image_confirm': {'tr': 'Resimi silmek istediğinize emin misiniz?', 'en': 'Are you sure to delete image?'},
    'scope': {'tr': 'Grup İsmi', 'en': 'Group Name'},
    'no_scope': {'tr': 'Grupsuz', 'en': 'Without Group'},
    'scope_already_exist': {'tr': 'Var Olan Grup', 'en': 'Group Already Exist'},
};

export { data };