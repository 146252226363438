import RouteList from '@/wam/config/RouteList.json';
/*
    {
      "meta": {
        "fa_icon": "fa fa-shopping-basket",
        "login": { "en": "yes", "tr": "yes" },
        "shop_active": { "en": "yes", "tr": "yes" }
      },
      "route_path": { "en": "/user-shoppingcart", "tr": "/kullanici-alisverissepeti" },
      "route_name": { "en": "user_shoppingcart", "tr": "kullanici_alisverissepeti" },
      "component_name": { "en": "user_shoppingcart", "tr": "user_shoppingcart" },
      "caption": { "en": "Shopping Cart", "tr": "Alışveriş Sepeti" },
      "is_dashboard": { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      "show_in_menu": { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      "click_type": { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      "list": []
    }
    {
        "data": {
            "general": {
                "caption": {"lang": {"en": "Shop", "tr": "Ma\u011faza"}, "val": "Shop"},
                "click_type": {"val": {"value": "0"} },
                "icon": {"val": "fa fa-shopping-bag"},
                "login_need": {"val": {"value": "no"} },
                "route_view": {"val": "shop"},
                "shop_active_need": {"val": {"value": "yes"} },
                "show_in_menu": {"val": {"value": "yes"} },
                "url": {"lang": {"en": "/shop", "tr": "/magaza"}, "val": "/shop"}
            }
        }, 
        "label": "/shop"
    }, 
*/



class ClsRoute {

  static f_createSystemMenuData = function() {
    let menu_list = [];
    for (let i in RouteList) {
      let parent_route_view = '';
      if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.parent && RouteList[i].data.general.parent.val) {
        parent_route_view = RouteList[i].data.general.parent.val;
      }
      if (!parent_route_view) {
        if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.route_view && RouteList[i].data.general.route_view.val) {
          let new_menu_item = this.f_createMenuItem(RouteList[i]);
          menu_list.push(new_menu_item);
        }
      }
    }

    for (let i in RouteList) {
      let parent_route_view = '';
      if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.parent && RouteList[i].data.general.parent.val) {
        parent_route_view = RouteList[i].data.general.parent.val;
      }
      if (parent_route_view) {
        if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.route_view && RouteList[i].data.general.route_view.val) {
          let sub_new_menu_item = this.f_createMenuItem(RouteList[i]);
          for (let sub_i in menu_list) {
            if (menu_list[sub_i].route_name === parent_route_view) {
              menu_list[sub_i].list.push(sub_new_menu_item);
              break
            }
          }
        }
      }
    }

    // console.log('menu_list : ', menu_list);
    return menu_list;
  };

  static f_createMenuItem = function(route_document) {
    let new_menu_item = {
      "meta": {
        "fa_icon": "",
        "permission": [],
        "login": "",
        "shop_active": "",
        'iframe_mode': "",
      },
      "is_dashboard": {"value": "0"},
      "show_in_menu": {"value": "0"},
      "list": []
    }
    let parent_route_view = '';
    if (route_document.data && route_document.data.general && route_document.data.general.parent && route_document.data.general.parent.val) {
      parent_route_view = route_document.data.general.parent.val;
    }

    if (route_document.data && route_document.data.general && route_document.data.general.route_view && route_document.data.general.route_view.val) {
      new_menu_item.route_name = route_document.data.general.route_view.val;
      new_menu_item.component_name = route_document.data.general.route_view.val;

      if (route_document.data && route_document.data.general && route_document.data.general.auth_list && route_document.data.general.auth_list.val) {
        new_menu_item.meta.permission = route_document.data.general.auth_list.val.split(',');
      }
      if (route_document.data && route_document.data.general && route_document.data.general.icon && route_document.data.general.icon.val) {
        new_menu_item.meta.fa_icon = route_document.data.general.icon.val;
      }
      if (route_document.data && route_document.data.general && route_document.data.general.login_need && route_document.data.general.login_need.val && route_document.data.general.login_need.val.value) {
        new_menu_item.meta.login = route_document.data.general.login_need.val.value;
      }
      if (route_document.data && route_document.data.general && route_document.data.general.shop_active_need && route_document.data.general.shop_active_need.val && route_document.data.general.shop_active_need.val.value) {
        new_menu_item.meta.shop_active = route_document.data.general.shop_active_need.val.value;
      }
      if (route_document.data && route_document.data.general && route_document.data.general.url && route_document.data.general.url.lang) {
        new_menu_item.route_path = route_document.data.general.url.lang;
      }
      if (route_document.data && route_document.data.general && route_document.data.general.caption && route_document.data.general.caption.lang) {
        new_menu_item.caption = route_document.data.general.caption.lang;
      }
      if (route_document.data && route_document.data.general && route_document.data.general.is_dashboard && route_document.data.general.is_dashboard.val && route_document.data.general.is_dashboard.val.value === 'yes') {
        new_menu_item.is_dashboard = {'value': '1'};
      }
      if (route_document.data && route_document.data.general && route_document.data.general.show_in_menu && route_document.data.general.show_in_menu.val && route_document.data.general.show_in_menu.val.value === 'yes') {
        new_menu_item.show_in_menu = {'value': '1'};
      }
      if (route_document.data && route_document.data.general && route_document.data.general.click_type && route_document.data.general.click_type.val && route_document.data.general.click_type.val.value) {
        new_menu_item.click_type = {'value': route_document.data.general.click_type.val.value};
      }
      if (route_document.data && route_document.data.general && route_document.data.general.iframe_mode && route_document.data.general.iframe_mode.val && route_document.data.general.iframe_mode.val.value) {
        // console.log(route_document.data.general.iframe_mode.val.value)
        new_menu_item.meta.iframe_mode = route_document.data.general.iframe_mode.val.value;
      }
    }

    return new_menu_item;
  };

  static f_getRoutePath = function(target_route_view, lang_value) {
    let route_path = '';
    for (let i in RouteList) {
      if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.route_view && RouteList[i].data.general.route_view.val) {
        if (RouteList[i].data.general.route_view.val && RouteList[i].data.general.route_view.val === target_route_view) {
          if (RouteList[i].data && RouteList[i].data.general && RouteList[i].data.general.url && RouteList[i].data.general.url.lang) {
            route_path = RouteList[i].data.general.url.lang[lang_value];
            break;
          }
        }
      }
    }
    return route_path;

  };  



}

export { ClsRoute };







